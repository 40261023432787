import React from 'react';
import styled from 'styled-components';
import SvgWhatsApp from '../icons/WhatsApp';

const SidebarIcons = styled.div`
  position: fixed;
  right: 0;
  top: 20%;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  svg {
    margin-bottom: -4px;
    color: #fff;
  }
`;

const SidebarWrapper = styled.div`
  background: #64b42e;
  border-radius: 25px 0 0 25px;
  width: 60px;
  height: 50px;
  transition: 200ms;
  &:hover {
    background: #529326;
  }
`;

function Sidebar(props) {
  return (
    <SidebarIcons>
      <SidebarWrapper>
        <a href="https://wa.me/4915678588757" target="_blank">
          <SvgWhatsApp />
        </a>
      </SidebarWrapper>
    </SidebarIcons>
  );
}

export default Sidebar;
