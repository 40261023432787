import React from 'react';
import Head from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import '@fontsource/open-sans'; // Default Weight 400
import '@fontsource/open-sans/700.css'; // Weight 700.
import { breakpoint } from '../breakpoints/breakpoints';
import Sidebar from './sidebar';

const GlobalStyle = createGlobalStyle`


  * {
    box-sizing: border-box; 
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    color: #909090;
  }

  h1 {
    font-weight: 700;
      font-size: 36px;
      line-height: 54px;

      @media ${breakpoint.mobileM} { 
      font-size: 40px;
      }
  }

  h2 {
    font-weight: 700;
      font-size: 25px;
      line-height: 181.18%;
  }

  h3 {
    font-weight: 700;
      font-size: 18px;
      line-height: 181.18%;
  }

  h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 156%;
  }

  h5 {
      font-weight: 700;
      font-size: 20px;
      line-height: 181.18%;

      @media ${breakpoint.laptop} { 
        font-size: 25px;
      }
    }

  p {
      font-weight: 400;
      font-size: 17px;
      line-height: 181.18%;

      @media ${breakpoint.laptop} { 
        font-size: 15px;
      }

    }
    a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
 }
`;

function Layout({ children }) {
  function isBrowser() {
    return typeof window !== 'undefined';
  }
  return (
    <React.Fragment>
      <noscript>
        <a href="https://www.provenexpert.com/de-de/spirituelle-hypnose-akademie?utm_source=seals&utm_campaign=proseal&utm_medium=profile&utm_content=4eb0d878-a816-4b88-ac47-420a6bae0068" target="_blank" title="Kundenbewertungen & Erfahrungen zu Spirituelle Hypnose Akademie. " className="pe-pro-seal-more-infos" >Mehr Infos</a>
      </noscript>

      <Head>
        {/* <script src="https://s.provenexpert.net/seals/proseal.js" ></script>
        <script id="proSeal">
          {isBrowser() && window.addEventListener('load', function(event) {
            window.provenExpert.proSeal({
              widgetId: "4eb0d878-a816-4b88-ac47-420a6bae0068",
              language:"de-DE",
              bannerColor: "#0DB1CD",
              textColor: "#FFFFFF",
              showReviews: true,
              hideDate: false,
              hideName: false,
              bottom: "130px",
              stickyToSide: "right",
              googleStars: true,
              zIndex: "9999",
            })
          })}
        </script> */}
        <script src="https://s.provenexpert.net/seals/proseal.js" ></script>
        <script id="proSeal">
          {isBrowser() && window.addEventListener('load', function(event) {
            window.provenExpert.proSeal({
              widgetId: "e075cb74-db5e-4eb9-95b0-e76b559fb043",
              language:"de-DE",
              bannerColor: "#0DB1CD",
              textColor: "#FFFFFF",
              showReviews: true,
              hideDate: true,
              hideName: false,
              hideOnMobile: false,
              bottom: "130px",
              stickyToSide: "right",
              googleStars: true,
              zIndex: "9999",
              displayReviewer: false,
              displayReviewerLastName: false,
            })
          })}
        </script>          
        <script src="https://cookiehub.net/c2/2688d9bb.js"></script>
        <script type="text/javascript">
          {isBrowser() &&
            document.addEventListener('DOMContentLoaded', function (event) {
              var cpm = {};
              window.cookiehub.load(cpm);
            })}
        </script>
      </Head>
      <GlobalStyle />
      {children}
      <Sidebar />
    </React.Fragment>
  );
}

export default Layout;
